import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'

import { booleanFilter } from '../../utils/helpers'
import { IAsset } from '../../utils/types'
import Button from '../UI/Button/Button'
import FeatureCardVertical from '../UI/FeatureCardVertical'
import Wrapper from '../UI/Wrapper'
import { ClientStoriesFilterSection, ClientStoriesGridSection } from './styles'

interface IClientStory {
  id: string
  url: string | null
  publish_details: {
    locale: string | null
  } | null
  client_story:
    | readonly ({
        headline: string | null
        short_description: string | null
        feature_image: IAsset | null
        industry:
          | readonly ({
              id: string
            } | null)[]
          | null
      } | null)[]
    | null
}

interface ClientStoriesFilterProps {
  allClientStories: readonly IClientStory[]
  filterFromLink: string
  content:
    | {
        headline: string | null
        show_filter_control: boolean | null
        filter_label: string | null
        load_more_text: string | null
        max_more_loaded: string | null
        select_default_text: string | null
        client_stories: readonly (IClientStory | null)[] | null
        industry_filters:
          | readonly ({
              id: string
              headline: string | null
            } | null)[]
          | null
      }
    | null
    | undefined
}

const ClientStoriesFilter = (props: ClientStoriesFilterProps) => {
  const { content, allClientStories, filterFromLink } = props
  const showFilterControl = content?.show_filter_control ?? true
  const maxMoreLoaded = Number(content?.max_more_loaded) || 6
  const [currentFilter, setCurrentFilter] = useState(filterFromLink)
  const [page, setPage] = useState(0)

  const initialCards: Record<string, readonly IClientStory[]> = {
    default: content?.client_stories?.filter(booleanFilter) ?? [],
  }

  const filteredCards = allClientStories.reduce((acc, story) => {
    const storiesByIndustry = story.client_story?.[0]?.industry
      ?.filter(booleanFilter)
      .map((industry): [string, IClientStory[]] => [
        industry.id,
        [...(acc[industry.id] ?? []), story],
      ])

    return storiesByIndustry ? { ...acc } : acc
  }, initialCards)

  const limit =
    page === 0 ? maxMoreLoaded : maxMoreLoaded + page * maxMoreLoaded

  const cardsToShow =
    currentFilter === ''
      ? filteredCards['default']?.slice(0, limit)
      : filteredCards[currentFilter]?.slice(0, limit)

  const loadMore = () => {
    setPage(page + 1)
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setCurrentFilter(event.target.value)
    setPage(0)
  }

  return (
    <section>
      {showFilterControl ? (
        <ClientStoriesFilterSection>
          <Wrapper>
            <Typography className="headline" component="h2" variant="h2">
              {content?.headline}
            </Typography>
            <Grid alignItems="center" container justifyContent="space-between">
              <Typography className="filterSubhead" component="p" variant="h3">
                {content?.filter_label}
              </Typography>
              <TextField
                className="textField"
                id="filterTopic"
                label={content?.select_default_text}
                name="filterTopic"
                onChange={handleChange}
                select
                value={currentFilter}
                variant="filled"
              >
                {content?.industry_filters
                  ?.filter(booleanFilter)
                  .map((filter) => (
                    <MenuItem key={filter.id} value={filter.id}>
                      {filter.headline}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Wrapper>
        </ClientStoriesFilterSection>
      ) : null}
      <ClientStoriesGridSection>
        <Wrapper>
          <Grid className="clientStoriesGrid" container>
            {cardsToShow?.map((story) => (
              <FeatureCardVertical
                body={story.client_story?.[0]?.short_description ?? ''}
                cardType=""
                featureImage={story.client_story?.[0]?.feature_image}
                headline={story.client_story?.[0]?.headline ?? ''}
                key={story.id}
                linkTarget={story.url ?? ''}
              />
            ))}
            {!cardsToShow?.length && (
              <Typography color="primary" component="p" variant="h3">
                No client stories in this category yet.
              </Typography>
            )}
          </Grid>
          {currentFilter === '' && limit < filteredCards['default'].length && (
            <Grid container justifyContent="center">
              <Button onClick={loadMore} type="button" variant="outline">
                {content?.load_more_text ?? ''}
              </Button>
            </Grid>
          )}
          {currentFilter && limit < filteredCards[currentFilter].length ? (
            <Grid container justifyContent="center">
              <Button onClick={loadMore} type="button" variant="outline">
                {content?.load_more_text ?? ''}
              </Button>
            </Grid>
          ) : null}
        </Wrapper>
      </ClientStoriesGridSection>
    </section>
  )
}

export default ClientStoriesFilter
