import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const ClientStoriesFilterSection = styled.div`
  background-color: ${rspTheme.palette.primary.background};
  padding: 78px 0 48px;

  .headline {
    color: ${rspTheme.palette.secondary.main};
    margin-bottom: 40px;
  }

  .filterSubhead {
    color: ${rspTheme.palette.primary.main};
    margin-bottom: 16px;
    margin-right: 48px;
  }

  .textField {
    min-width: 320px;
    max-width: 820px;
    margin-bottom: 12px;
    flex-grow: 1;

    .MuiFilledInput-root {
      background-color: transparent;
    }

    .MuiInputBase-input {
      background-color: #ffffff;
      border-radius: 4px;
      margin-bottom: 0;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    }

    .MuiFilledInput-input {
      padding: 24px 12px 16px;
    }

    .MuiFilledInput-underline::after {
      border-bottom-color: transparent;
    }

    .MuiFilledInput-underline::before {
      border-bottom-color: transparent;
    }

    .MuiFormControl-root {
      position: relative;
    }

    .MuiFormLabel-root {
      color: ${rspTheme.palette.primary.main};
    }

    .MuiFormHelperText-root {
      position: absolute;
      bottom: 2px;
      letter-spacing: -0.6px;
    }

    .MuiFormHelperText-contained {
      margin-left: 0;
      width: 100%;
    }

    .MuiInputLabel-filled.MuiInputLabel-shrink {
      transform: translate(12px, 6px) scale(0.75);
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 32px 0 24px;

    .headline {
      font-size: 2.8rem;
      margin-bottom: 16px;
    }

    .filterSubhead {
      font-size: 1.6rem;
      font-weight: 400;
      width: 100%;
    }

    .textInput {
      width: 100%;
    }
  }
`

export const ClientStoriesGridSection = styled.div`
  padding: 96px 0 72px;

  .clientStoriesGrid {
    gap: 32px;
    justify-content: flex-start;
    margin-bottom: 48px;

    > span {
      width: 31%;
      min-width: 260px;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 28px 0 48px;

    .clientStoriesGrid {
      gap: 24px;
      justify-content: center;
    }
  }
`
