import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import ClientStoriesFilter from '../components/ClientStoriesFilter'
import Hero from '../components/Hero'
import ImpactModule from '../components/Impact'
import Layout from '../components/Layout'
import RelatedSolutions from '../components/RelatedSolutions'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import TrainstopSection from '../components/TrainstopSection'
import Testimonial from '../components/UI/Testimonial'
import { booleanFilter } from '../utils/helpers'

const topicHasHeadline = (
  topic: unknown | { headline: string },
): topic is { headline: string } =>
  (topic as { headline: string })?.headline !== undefined

const topicHasSubheading = (
  topic: unknown | { subheading: string },
): topic is { subheading: string } =>
  (topic as { subheading: string })?.subheading !== undefined

const topicHasFeatureImage = (
  topic: unknown | { feature_image: Queries.Query['contentstackAssets'] },
): topic is { feature_image: Queries.Query['contentstackAssets'] } =>
  (topic as { feature_image: Queries.Query['contentstackAssets'] })
    ?.feature_image !== undefined

const stateHasfilterId = (
  state: unknown | { filterId: string },
): state is { filterId: string } =>
  (state as { filterId: string })?.filterId !== undefined

const isHeroTrainStopIsYesOrNo = (
  is_hero_train_stop: unknown | 'Yes' | 'No',
): is_hero_train_stop is 'Yes' | 'No' =>
  (is_hero_train_stop as 'Yes' | 'No') === 'Yes' ||
  (is_hero_train_stop as 'Yes' | 'No') === 'No'

const ClientStoriesOverviewPage = ({
  data,
  location,
  pageContext,
}: PageProps<Queries.ClientStoriesOverviewPageQuery, { slug: string }>) => {
  const { contentstackPageClientStoriesOverview: pageData } = data
  const breadcrumbLinks = [{ text: pageData?.title || '' }]

  const allClientStories = data?.allClientStories?.nodes
  const filterFromLink = stateHasfilterId(location.state)
    ? location.state.filterId
    : ''
  const clientStoriesByLocale = allClientStories.filter(
    (story) =>
      story.publish_details?.locale === pageData?.publish_details?.locale,
  )

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    (topicHasHeadline(pageData?.page_header?.[0]?.topic?.[0])
      ? pageData?.page_header?.[0]?.topic?.[0]?.headline
      : '')
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    (topicHasSubheading(pageData?.page_header?.[0]?.topic?.[0])
      ? pageData?.page_header?.[0]?.topic?.[0]?.subheading
      : '')
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    (topicHasFeatureImage(pageData?.page_header?.[0]?.topic?.[0])
      ? pageData?.page_header?.[0]?.topic?.[0]?.feature_image
      : null)

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={pageContext.slug}
      title={metaTitle}
    >
      <Hero content={pageData?.page_header?.[0]} />
      {pageData?.impact?.[0] ? (
        <ImpactModule
          content={{
            theme: 'Light',
            ...pageData?.impact?.[0],
          }}
        />
      ) : null}
      {pageData?.train_stop?.[0] ? (
        <TrainstopSection
          ctaText={pageData?.train_stop?.[0]?.cta_text ?? undefined}
          headline={pageData?.train_stop?.[0]?.headline ?? ''}
          isHeroTrainStop={
            isHeroTrainStopIsYesOrNo(
              pageData?.train_stop?.[0]?.is_hero_train_stop,
            )
              ? pageData?.train_stop?.[0]?.is_hero_train_stop
              : undefined
          }
          slides={
            pageData?.train_stop?.[0]?.slides?.filter(booleanFilter) ?? []
          }
        />
      ) : null}
      <ClientStoriesFilter
        allClientStories={clientStoriesByLocale}
        content={pageData?.client_stories_filter?.[0]}
        filterFromLink={filterFromLink}
      />
      {pageData?.testimonial?.[0] ? (
        <Testimonial
          citation={pageData?.testimonial?.[0]?.testimonial?.[0]?.source ?? ''}
          testimonial={
            pageData?.testimonial?.[0]?.testimonial?.[0]?.testimonial ?? ''
          }
        />
      ) : null}
      {pageData?.featured_programs?.[0] ? (
        <RelatedSolutions relatedSolutions={pageData?.featured_programs?.[0]} />
      ) : null}
    </Layout>
  )
}

export const Head = ({
  data,
}: HeadProps<Queries.ClientStoriesOverviewPageQuery>) => (
  <>
    <SchemaMarkup
      data={data.contentstackPageClientStoriesOverview?.schema_markup}
    />
    <SchemaMarkupBreadcrumb
      pageTitle={data?.contentstackPageClientStoriesOverview?.title}
    />
  </>
)

export default ClientStoriesOverviewPage

export const data = graphql`
  query ClientStoriesOverviewPage($pageId: String, $locale: String) {
    contentstackPageClientStoriesOverview(id: { eq: $pageId }) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ...PageHeaderSection
      }
      client_stories_filter {
        headline
        show_filter_control
        filter_label
        load_more_text
        max_more_loaded
        select_default_text
        industry_filters {
          id
          headline
        }
        client_stories {
          id
          url
          publish_details {
            locale
          }
          client_story {
            headline
            short_description
            industry {
              id
            }
            feature_image {
              description
              imgixImage {
                gatsbyImageData(width: 325, imgixParams: { q: 60 })
              }
            }
          }
        }
      }
      testimonial {
        ...TestimonialSection
      }
      featured_programs {
        ...FeaturedProgramsSection
      }
      impact {
        ...ImpactStatsSection
      }
      train_stop {
        ...TrainStopSection
      }
    }

    allClientStories: allContentstackPageClientStory(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        id
        url
        publish_details {
          locale
        }
        client_story {
          headline
          short_description
          industry {
            id
          }
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 325, imgixParams: { q: 60 })
            }
          }
        }
      }
    }
  }
`
